
import { Vue, Component } from 'vue-property-decorator';
import TableBlock from '@/components/table/Table.vue';
import Loading from '@/components/Loading.vue';

import ApprovedUsersModule from '@/admin/store/sellers/auctionHouses/approvedUsers';
import { PageInterface } from '@/lib/layouts/page/page.interface';

@Component({
    components: {
        Loading,
        TableBlock,
    },
})
export default class ApprovedUsersComponent extends Vue {
    get isLoading(): boolean {
        return ApprovedUsersModule.isLoading;
    }

    get settings(): PageInterface {
        return ApprovedUsersModule.pageSettings;
    }

    tableAction(params: any): void {
        console.info('tableAction params', params);
    }

    created(): void {
        ApprovedUsersModule.init(+this.$route.params.auctionHouseId);
    }

    beforeDestroy(): void {
        ApprovedUsersModule.reset();
    }
}
