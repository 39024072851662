import { Vue, Component } from 'vue-property-decorator';
import AuctionHouseEntity from '@/admin/store/sellers/auctionHouses/entity';

import Inputmask from 'inputmask';
import { floatRegExp, numberRegExp } from '@/constants';

@Component
export default class AuctionHouseMixin extends Vue {
    get model() {
        return AuctionHouseEntity.model;
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }

        const phoneMask = new Inputmask(AuctionHouseEntity.model.phone.mask);
        const inputsPhone = this.$el.getElementsByClassName('j_phone_mask_wrapper');
        const emailMask = new Inputmask(AuctionHouseEntity.model.email.mask);
        const inputsEmail = this.$el.getElementsByClassName('j_email_mask_wrapper');

        for (const item of Object.values(inputsPhone)) {
            phoneMask.mask(item.getElementsByTagName('input')[0]);
        }

        for (const item of Object.values(inputsEmail)) {
            emailMask.mask(item.getElementsByTagName('input')[0]);
        }

        const floatMask = new Inputmask({ regex: floatRegExp, showMaskOnHover: false });
        const inputsFloat = this.$el.getElementsByClassName('j_float_mask_wrapper');

        for (const item of Object.values(inputsFloat)) {
            floatMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    mounted() {
        this.initMask();
    }
}
