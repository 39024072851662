
import { Mixins, Component, Watch } from 'vue-property-decorator';
import AuctionHouseEntity from '@/admin/store/sellers/auctionHouses/entity';
import AuctionHouseMixin from './AuctionHouseMixin';

import SiteModule from '@/admin/store/site';
import UserModule from '@/admin/store/user';

import InputBlock from '@/components/form/InputMain.vue';
import RadioBtnGroup from '@/components/form/RadioBtn.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';
import TableBlock from '@/components/table/TableInputs.vue';

import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import OuterAddition from '@/components/form/OuterAddition.vue';

@Component({
    components: {
        OuterAddition,
        InputBlock,
        RadioBtnGroup,
        SelectCustom,
        CheckboxCustom,
        TableBlock,
    },
})
export default class AuctionHouseRulesComponent extends Mixins(AuctionHouseMixin) {
    componentCounter = 0;

    @Watch('isLoading')
    onIsLoadingChange(bool: boolean): void {
        if (bool === false) {
            this.componentCounter++;
        }
    }

    get module(): typeof AuctionHouseEntity {
        return AuctionHouseEntity;
    }

    get isLoading(): boolean {
        return this.module.isLoading;
    }

    get isStage() {
        return SiteModule.isStage;
    }

    get table() {
        return AuctionHouseEntity.table;
    }

    get isSuperAdmin(): boolean {
        return UserModule.isSuperAdmin;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    tableAction(params: any) {
        if (params.action === 'radio') {
            const paramsArr = params.data.id.split('_');
            AuctionHouseEntity.updateStepType({ id: Number(paramsArr[0]), value: paramsArr[2] });
        }

        if (params.action === 'delete') {
            AuctionHouseEntity.deleteStep(params.id);
        }
    }

    focusoutHandler(params: { id: number; action: string; data: string }): void {
        if (params.action === 'input_to') {
            const value = +formatRemoveSpaces(params.data);

            if (!isFinite(value)) {
                return;
            }

            AuctionHouseEntity.updateToValueChange({ id: params.id, value });
        }

        if (params.action === 'from') {
            const value = +formatRemoveSpaces(params.data);

            if (!isFinite(value)) {
                return;
            }

            AuctionHouseEntity.updateFromValueChange({ id: params.id, value });
        }
    }

    inputHandler(params: { id: number; action: string; data: string }): void {
        if (params.action === 'input_to') {
            AuctionHouseEntity.updateToValue({ id: params.id, value: params.data });
        }

        if (params.action === 'from') {
            AuctionHouseEntity.updateFromValue({ id: params.id, value: params.data });
        }

        if (params.action === 'input_plainStep' || params.action === 'input_percentStep') {
            const value = +formatRemoveSpaces(params.data);

            if (!isFinite(value)) {
                return;
            }

            AuctionHouseEntity.updateStepValue({ id: params.id, value: value });
        }
    }

    addStep() {
        AuctionHouseEntity.addStep();
    }

    updatePostAuctionSaleOfLotsHours(value: string) {
        AuctionHouseEntity.updatePostAuctionSaleOfLotsHours(formatRemoveSpaces(value));
    }

    updateCommissionSite(value: string) {
        AuctionHouseEntity.updateCommissionSite(formatRemoveSpaces(value));
    }

    updateCommissionAuctionHouse(value: string) {
        AuctionHouseEntity.updateCommissionAuctionHouse(value);
    }

    updateNewbieMoneyLimitType(value: string) {
        AuctionHouseEntity.updateNewbieMoneyLimitType(value);
    }

    updateNewbieMoneyLimit(value: string) {
        AuctionHouseEntity.updateNewbieMoneyLimit(formatRemoveSpaces(value));
    }

    updateAuctionPublishPrice(value: string) {
        AuctionHouseEntity.updateAuctionPublishPrice(formatRemoveSpaces(value));
    }

    updateIsPostauctionSale(params: { id: number; name: undefined; bool: boolean }) {
        AuctionHouseEntity.updateIsPostAuctionSale(params.bool);
    }
}
