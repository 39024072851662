
import { Prop, Mixins, Component } from 'vue-property-decorator';

import DropOverlay from '@/components/imageInput/DropOverlay.vue';
import ImageInputBlock from '@/components/imageInput/ImageInput.vue';

import DragMixin from '@/components/imageInput/DragMixin';

import CatalogEntity from '@/admin/store/auctions/catalogsAuctions/entityCommon';
import { CatalogModelInterface } from '@/interfaces/models/catalog.interface';
import { ImageInput } from '@/lib/formFactory/imageInput.interface';
import { imageToFile } from '@/lib/utils/Utils';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

enum imageTypeEnum {
    PHOTO = 'photo',
    COVER = 'coverPhoto',
}

@Component({
    components: {
        ImageInputBlock,
        DropOverlay,
    },
})
export default class CatalogImageInputs extends Mixins(DragMixin) {
    @Prop({ default: '' }) entity!: string;

    infoText = 'Минимальное разрешение изображения 480x480 пикселей, допустимые форматы файлов .jpg, .jpeg, .png';
    isOverlayHidden = true;
    validationIndex = 0;
    validatedFiles: File[] = [];
    errorMessages: { [key: string]: string } = {
        fileType: 'Ошибка! Неверный формат файла(ов)',
        fileDimensions: 'Изображение не должно быть меньше 480x480 пикселей',
    };
    filesToValidateLength = 0;
    imageType = {
        photo: imageTypeEnum.PHOTO,
        cover: imageTypeEnum.COVER,
    };

    get module(): typeof CatalogEntity {
        return CatalogEntity;
    }

    get model(): CatalogModelInterface {
        return this.module.model;
    }

    get imageMain(): ImageInput {
        return this.model.photo;
    }

    get imageCover(): ImageInput {
        return this.model.coverPhoto;
    }

    async handleImage(file: File, type: imageTypeEnum): Promise<void> {
        const toFile = await imageToFile(file);

        this.module.updateImageFileByType({ type, file: toFile });
    }

    removePreview(type: imageTypeEnum): void {
        this.module.removeImagePreviewByType(type);
    }

    handleDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (!event?.dataTransfer?.files?.length) {
            return;
        }

        this.filesToValidateLength = event.dataTransfer.files.length;
        this.validateFiles(event.dataTransfer.files);
    }

    async validateFiles(fileList: FileList): Promise<void> {
        const fileListLength = Object.values(fileList).length;
        if (!fileList || !fileListLength) {
            return;
        }

        for (const file of Object.values(fileList)) {
            if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
                ResponseHandlerModule.showNotify({ message: this.errorMessages['fileType'], type: 'fail' });
                this.incrementValidationIndex();
                continue;
            }

            const fr = new FileReader();

            fr.onload = () => {
                const img = new Image();

                img.onload = () => {
                    if (img.width < 480 || img.height < 480) {
                        ResponseHandlerModule.showNotify({
                            message: this.errorMessages['fileDimensions'],
                            type: 'fail',
                        });
                        this.incrementValidationIndex();

                        return;
                    }

                    this.validatedFiles.push(file);
                    this.incrementValidationIndex();
                };

                img.src = fr.result as string;
            };

            fr.readAsDataURL(file);
        }
    }

    hideOverlay(): void {
        this.isOverlayHidden = true;
    }

    incrementValidationIndex(): void {
        this.validationIndex++;

        if (this.validationIndex === this.filesToValidateLength) {
            this.addImages(this.validatedFiles);
            this.hideOverlay();

            this.filesToValidateLength = 0;
            this.validationIndex = 0;
            this.validatedFiles = [];
        }
    }

    addImages(files: File[]): void {
        if (!files.length) {
            return;
        }

        if (files.length >= 2) {
            this.handleImage(files[0], imageTypeEnum.PHOTO);
            this.handleImage(files[1], imageTypeEnum.COVER);

            return;
        }

        if (!this.imageMain.value && !this.imageMain.file && !this.imageCover.value && !this.imageCover.file) {
            this.handleImage(files[0], imageTypeEnum.PHOTO);
            this.handleImage(files[0], imageTypeEnum.COVER);

            return;
        }

        if (!this.imageMain.value) {
            this.handleImage(files[0], imageTypeEnum.PHOTO);

            return;
        }

        if (!this.imageCover.value) {
            this.handleImage(files[0], imageTypeEnum.COVER);

            return;
        }

        this.handleImage(files[0], imageTypeEnum.PHOTO);
    }

    handleChange(files: File[]): void {
        this.addImages(files);
    }
}
