
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import Loading from '@/components/Loading.vue';
import ApprovedUserEntity from '@/admin/store/sellers/auctionHouses/approvedUsersEntity';

import Info from './_info.vue';
import Requests from './_requests.vue';
import Statistics from './_statistics.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import { tabsNavInterface } from '@/interfaces/tabs';

@Component({
    components: {
        Layout,
        Loading,
        Info,
        Requests,
        Statistics,
        TabsNav,
        BtnSubmit,
        BtnDefault,
    },
})
export default class ApprovedUserStatistics extends Vue {
    get isLoading(): boolean {
        return ApprovedUserEntity.isLoading;
    }

    get tabsNav(): tabsNavInterface {
        return ApprovedUserEntity.tabsNav;
    }

    get currentTab(): string {
        return ApprovedUserEntity.tabsNav[this.$route.name || 'approved_user'].id;
    }

    send(): void {
        ApprovedUserEntity.send();
    }

    cancel(): void {
        this.init();
    }

    init(): void {
        ApprovedUserEntity.initById(+this.$route.params.userId);
    }

    created(): void {
        this.init();
    }

    beforeDestroy(): void {
        ApprovedUserEntity.reset();
    }
}
