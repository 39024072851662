import InputEntity from '@/lib/formFactory/inputEntity';
import RadioEntity from '@/lib/formFactory/radioEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const operationTypeEntity = new RadioEntity('operationType');
        operationTypeEntity.setTitle('');
        operationTypeEntity.setList([
            { id: 0, name: 'Поступление', value: 'receipt' },
            { id: 1, name: 'Выплата', value: 'pay' },
        ]);
        operationTypeEntity.setRequired(true);
        operationTypeEntity.setValue('receipt');

        const sumEnity = new InputEntity('sum');
        sumEnity.setTitle('Сумма');
        sumEnity.setRequired(true);

        const sellerTypeEntity = new SelectEntity('sellerType');
        sellerTypeEntity.setTitle('Тип продавца');
        sellerTypeEntity.setList([
            { id: 'sellerAuctionHouse', value: 'Аукционный дом' },
            { id: 'sellerGallery', value: 'Галерея' },
            { id: 'sellerPainter', value: 'Автор' },
        ]);
        sellerTypeEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerTypeEntity.setRequired(true);

        const sellerAuctionHouseEntity = new InputSuggestionsEntity('sellerAuctionHouse');
        sellerAuctionHouseEntity.setTitle('Аукционный дом');
        sellerAuctionHouseEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerAuctionHouseEntity.setRequired(true);
        sellerAuctionHouseEntity.setDisabled(true);

        const catalogEntity = new InputSuggestionsEntity('catalog');
        catalogEntity.setTitle('Каталог');
        catalogEntity.setValidation({
            empty: 'Поле не заполнено',
        });

        const orderEntity = new InputSuggestionsEntity('order');
        orderEntity.setTitle('Заказ');
        orderEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        orderEntity.setRequired(true);

        const commentEnity = new InputEntity('comment');
        commentEnity.setTitle('Комментарий');

        this.model = {
            operationType: operationTypeEntity.entity[operationTypeEntity.entityName],
            sum: sumEnity.entity[sumEnity.entityName],
            comment: commentEnity.entity[commentEnity.entityName],
            sellerType: sellerTypeEntity.entity[sellerTypeEntity.entityName],
            sellerAuctionHouse: sellerAuctionHouseEntity.entity[sellerAuctionHouseEntity.entityName],
            catalog: catalogEntity.entity[catalogEntity.entityName],
            orderDetails: {
                publish_price: {
                    title: 'Стоимость размещения',
                    value: '',
                },
                amount: {
                    title: 'Стоимость всех лотов',
                    value: '',
                },
                commission_right_to_follow: {
                    title: 'Сумма комиссии за право следования по всем лотам',
                    value: '',
                },
                commission_auction_house: {
                    title: 'Комиссия АД по всем лотам',
                    value: '',
                },
                commission_site: {
                    title: 'Комиссии площадки по всем лотам',
                    value: '',
                },
                commission_financial_services: {
                    title: 'Комиссия за финансовые операции',
                    value: '',
                },
                total_catalog_balance: {
                    title: 'Общий баланс по каталогу-аукциону',
                    value: '',
                },
            },
        };
    }
}
