/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'AuctionHouseCatalogsFilter/updateFilter',
        reset: 'AuctionHouseCatalogsFilter/resetFilter',
        resetState: 'AuctionHouseCatalogsFilter/resetFilterState',
        removeSelected: 'AuctionHouseCatalogsFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();
        filter.createSearchEntity('name');
        filter.setPlaceholder('name', 'По названию');
        filter.setName('name', 'search_name');
        filter.setPreviewName('name', 'Название');
        filter.setIconClassName('name', 'icon-book');
        filter.setAction('name', 'AuctionHouseCatalogs/updateSearch');

        filter.createCheckboxEntity('withRemoved');
        filter.setName('withRemoved', 'checkbox_withRemoved');
        filter.setTitle('withRemoved', 'Показывать удалённые');
        filter.setPreviewName('withRemoved', 'Удалённые');
        filter.setAction('withRemoved', 'AuctionHouseCatalogs/updateCheckboxFilter');
        filter.setIconClassName('withRemoved', 'icon-book');
        filter.setIgnoreNull('withRemoved', true);
        filter.setList('withRemoved', [
            {
                id: 'withRemoved',
                name: 'Показывать удалённые',
                value: 'withRemoved',
                checked: false,
            },
        ]);

        this.filterModel = filter.filterModel;
    }
}
