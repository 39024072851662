import { Module, Action, getModule } from 'vuex-module-decorators';
import TransactionsBaseModule from '@/admin/store/transactions';
import PageEntity from '@/lib/layouts/page/pageEntity';

import store from '@/admin/store';
import { getTransactionsList } from '@/admin/api/transaction';

const MODULE_NAME = 'auctionHouseTransactions';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class AuctionHouseTransactionsModule extends TransactionsBaseModule {
    constructor(module: AuctionHouseTransactionsModule) {
        super(module);

        this.isLoading = false;

        const page = new PageEntity();
        page.setSortName('AuctionHouseTransactionsSort');
        page.setDefaultSortField('createdAt');
        this.pageSettings = page.values;
    }

    @Action({ rawError: true })
    async getTable(params: { sellerName?: string; catalogName?: string; orderId?: string | number }) {
        this.SET_IS_LOADING(true);

        const promises = [];
        if (!this.operationNames.length) {
            promises.push(this.initOperationNames());
        }

        if (!this.statuses.length) {
            promises.push(this.initOrderStatuses());
        }

        await Promise.all(promises);

        this.SET_REQUEST_PARAMS(params);

        const sort = await this.getSortForRequest();
        let filter = '&filters[0][id]=subjectType&filters[0][value]=auction_house';
        filter += params.sellerName ? `&filters[1][id]=seller&filters[1][value]=${params.sellerName}` : '';
        filter += params.catalogName ? `&filters[2][id]=auction&filters[2][value]=${params.catalogName}` : '';
        filter += params.orderId ? `&filters[3][id]=order&filters[3][value]=${params.orderId}` : '';

        const result = await getTransactionsList(
            this.pageSettings.pageCurrent,
            sort,
            filter,
            +this.pageSettings.pageAmountItems,
        );

        await this.setInfo(result);
        this.SET_IS_LOADING(false);
    }
}
export default getModule(AuctionHouseTransactionsModule);
