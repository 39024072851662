
import { Vue, Component } from 'vue-property-decorator';

import AuctionHouseEntity from '@/admin/store/sellers/auctionHouses/entity';
import AuctionHouseTransactions from '@/admin/store/sellers/auctionHouses/transactions';
import AuctionHouseTransactionEntity from '@/admin/store/sellers/auctionHouses/transactionEntity';
import UserModule from '@/admin/store/user';
import RoleModule, { RoleEnum } from '@/admin/store/role';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import WarningModal from '@/components/modules/Warning.vue';
import TransactionForm from '@/components/Transaction.vue';
import Statistics from '@/components/Statistics.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';

@Component({
    components: {
        Loading,
        Statistics,
        TableBlock,
        WarningModal,
        TransactionForm,
    },
})
export default class AuctionHouseBalanceComponent extends Vue {
    isWarningShow = false;
    texts = {
        no: 'Нет',
        yes: 'Да',
        title: 'Внимание',
        description: 'Вы действительно хотите отменить транзакцию?',
    };
    transactionToDelete = 0;
    statisticsUpdated = 0;

    get isWriteAllow(): boolean {
        return RoleModule.accessByRouterName[this.$route.name as RoleEnum]?.write.includes(UserModule.role as RoleEnum);
    }

    get settings() {
        return AuctionHouseTransactions.pageSettings;
    }

    get isSubmitBlocked() {
        return AuctionHouseTransactionEntity.isSendBlocked;
    }

    get model() {
        return AuctionHouseEntity.model;
    }

    get isTableLoading() {
        return AuctionHouseTransactions.isLoading;
    }

    get isOrderLoading() {
        return AuctionHouseTransactionEntity.isLoading;
    }

    get transactionModel() {
        return AuctionHouseTransactionEntity.model;
    }

    async selectAmount(items: string) {
        await AuctionHouseTransactions.updateItems(items);
        this.requestTable();
    }

    get paginationAction() {
        return AuctionHouseTransactions.updatePage;
    }

    get paginationItems(): { items: string } {
        return {
            items: AuctionHouseTransactions.pageSettings.pageAmountItems,
        };
    }

    requestTable() {
        if (!this.transactionModel.sellerAuctionHouse.current.value) {
            return;
        }

        AuctionHouseTransactions.getTable({
            sellerName: this.transactionModel.sellerAuctionHouse.current.value,
            catalogName: this.transactionModel.catalog.current.value,
        });
    }

    async tableAction(params: any) {
        if (params.action === 'sort') {
            await AuctionHouseTransactions.updateSortValue(params);
            this.requestTable();
        }

        if (params.action === 'delete') {
            this.transactionToDelete = params.id;
            this.isWarningShow = true;
        }
    }

    async transactionFormHandler(event: { action: string; value: string | InputSuggestionItem }) {
        if (event.action === 'operationType') {
            if (typeof event.value !== 'string') {
                return;
            }

            AuctionHouseTransactionEntity.updateOperationType(event.value);
        }

        if (event.action === 'sum') {
            if (typeof event.value !== 'string') {
                return;
            }

            AuctionHouseTransactionEntity.updateSum(event.value);
        }

        if (event.action === 'comment') {
            if (typeof event.value !== 'string') {
                return;
            }

            AuctionHouseTransactionEntity.updateComment(event.value);
        }

        if (event.action === 'searchCatalog') {
            if (typeof event.value !== 'string') {
                return;
            }

            AuctionHouseTransactionEntity.updateSearchCatalog(event.value);
        }

        if (event.action === 'selectCatalog') {
            if (typeof event.value === 'string') {
                return;
            }

            AuctionHouseTransactionEntity.updateSelectedCatalog(event.value);
            this.requestTable();
        }
    }

    async removeTransaction() {
        await AuctionHouseTransactionEntity.cancelTransaction(this.transactionToDelete);

        this.transactionToDelete = 0;
        this.isWarningShow = false;

        this.requestTable();
    }

    async submit() {
        const isValidationError = await AuctionHouseTransactionEntity.send();

        if (isValidationError) {
            return;
        }

        this.statisticsUpdated++;

        this.requestTable();
    }

    closeModal() {
        this.isWarningShow = false;
        this.transactionToDelete = 0;
    }

    async created(): Promise<void> {
        AuctionHouseTransactions.setDefaultSort(false);

        if (AuctionHouseEntity.auctionHouseName) {
            AuctionHouseTransactionEntity.initSellerInfo({
                id: +this.$route.params.auctionHouseId,
                value: AuctionHouseEntity.auctionHouseName,
            });
            await this.requestTable();

            return;
        }

        await AuctionHouseEntity.initCardById(this.$route.params.auctionHouseId);

        AuctionHouseTransactionEntity.initSellerInfo({
            id: AuctionHouseEntity.cardId as number,
            value: AuctionHouseEntity.auctionHouseName,
        });
        await this.requestTable();
    }

    beforeDestroy() {
        AuctionHouseTransactionEntity.reset();
        AuctionHouseTransactions.resetTable();
    }
}
