
import { Vue, Component, Watch } from 'vue-property-decorator';
import AuctionsCommonEntityModule from '@/admin/store/auctions/catalogsAuctions/entityCommon';

import RadioBtn from '@/components/form/RadioBtn.vue';
import InputBlock from '@/components/form/InputMain.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import CheckboxCustom from '@/components/form/Checkbox.vue';
import ExchangeRates from '@/components/templates/ExchangeRates.vue';
import SuggestionsTree from '@/components/suggestions/Tree.vue';

import Inputmask from 'inputmask';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { numberRegExp } from '@/constants';
import OuterAddition from '@/components/form/OuterAddition.vue';
import { AuctionTypeEnum } from '@/admin/store/auctions/catalogsAuctions/entityCommonModel';
import { CatalogModelInterface } from '@/interfaces/models/catalog.interface';

@Component({
    components: {
        OuterAddition,
        RadioBtn,
        InputBlock,
        SelectCustom,
        CheckboxCustom,
        ExchangeRates,
        SuggestionsTree,
    },
})
export default class AuctionCommonRulesComponent extends Vue {
    componentCounter = 0;

    text = {
        post_auction_sale_hours: 'часов',
        trade: {
            auto_in_order: 'секунд',
            auto_one_time: 'дней',
        },
        renewal: {
            auto_in_order: 'секунд',
            auto_one_time: 'минут',
        },
        lotInterval: 'минут',
    };

    @Watch('componentCounterTrigger')
    onComponentCounterTriggerChange(): void {
        this.componentCounter++;
    }

    get module(): typeof AuctionsCommonEntityModule {
        return AuctionsCommonEntityModule;
    }

    get componentCounterTrigger(): boolean[] {
        return [this.model.isPostAuctionSale.list[0].checked, this.model.isCorrespondenceBidding.list[0].checked];
    }

    get model(): CatalogModelInterface {
        return this.module.model;
    }

    get isLotIntervalDisabled(): boolean {
        return this.model.auctionType.value !== AuctionTypeEnum.AutoOneTime;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    updateExchangeRateType(value: string) {
        AuctionsCommonEntityModule.updateExchangeRateType(value);
    }

    updateIsCorrespondenceBidding(params: { name: string; id: number; bool: boolean }): void {
        AuctionsCommonEntityModule.updateIsCorrespondenceBidding(params.bool);
    }

    updateIsPostAuctionSale(params: { name: string; id: number; bool: boolean }) {
        AuctionsCommonEntityModule.updateIsPostAuctionSale(params.bool);
    }

    updateLotInterval(value: string): void {
        AuctionsCommonEntityModule.updateModelFieldValue({ field: 'lotInterval', value: formatNumberSpaced(value) });
    }

    updateSelectedCurrency(params: { id: number; selected: boolean; value: string }): void {
        AuctionsCommonEntityModule.updateSelectedCurrency(params);
    }

    updateAuctionType(value: string) {
        AuctionsCommonEntityModule.updateAuctionType(value);
    }

    updatePostAuctionSaleHours(value: string) {
        AuctionsCommonEntityModule.updatePostAuctionSaleHours(formatRemoveSpaces(value));
    }

    updateOneLotTradeTimeSeconds(value: string) {
        AuctionsCommonEntityModule.updateOneLotTradeTimeSeconds(formatRemoveSpaces(value));
    }

    updateRenewalTimeSeconds(value: string) {
        AuctionsCommonEntityModule.updateRenewalTimeSeconds(formatRemoveSpaces(value));
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputs = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputs)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    mounted() {
        this.initMask();
    }
}
