
import { Vue, Component } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import TableBlock from '@/components/table/Table.vue';
import Loading from '@/components/Loading.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

import UsersModule from '@/admin/store/sellers/auctionHouses/users';

@Component({
    components: {
        FlexLayout,
        TableBlock,
        Loading,
        AppHeaderMain,
    },
})
export default class AuctionHouseUsersComponent extends Vue {
    get settings() {
        return UsersModule.pageSettings;
    }

    get filterSettings() {
        return UsersModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return UsersModule.isLoading;
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            UsersModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        UsersModule.getList();
    }

    async resetFilters() {
        await UsersModule.clearSort();
        UsersModule.getList();
    }

    toAdd(): void {
        this.$router.push({ name: 'user_create' });
    }

    created() {
        UsersModule.init(+this.$route.params.auctionHouseId);
    }

    beforeDestroy() {
        UsersModule.reset();
    }
}
