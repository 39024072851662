<template>
    <div
        ref="timePickerDefault"
        class="cp-input-fake__wrapper cp-time-picker"
        :class="{ disabled: data.disabled || disabled, 'required-field-wrapper': data.required }"
    >
        <div class="cp-input-fake cp-input-radius-50" @click="openRange">
            <span class="mr-input-fake__value">{{ data.hour }}:{{ data.minute }}</span>
            <font-awesome-icon class="mr-input-fake__icon-time" size="lg" :icon="['far', 'clock']" />
        </div>

        <div v-if="visible" class="cp-time-clock__wrapper">
            <v-time-picker
                :value="`${data.hour}:${data.minute}`"
                :allowed-minutes="allowedSteps"
                no-title
                format="24hr"
                scrollable
                @click:hour="selectHourValue"
                @click:minute="selectMinuteValue"
            />
        </div>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

library.add(faClock);
import TimePickerMixin from './TimePickerMixin';

export default {
    components: {
        FontAwesomeIcon,
    },
    mixins: [TimePickerMixin],
};
</script>
