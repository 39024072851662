export default {
    auction_house_approved_user: {
        id: 'auction_house_approved_user',
        name: 'Статистика',
        template: 'auction_house_approved_user',
        pathName: 'auction_house_approved_user',
        icon: '',
        iconSize: '16',
    },
    auction_house_approved_user_requests: {
        id: 'auction_house_approved_user_requests',
        name: 'Запросы',
        template: 'auction_house_approved_user_requests',
        pathName: 'auction_house_approved_user_requests',
        icon: '',
        iconSize: '16',
    },
};
