import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import store from '@/admin/store';
import { getCatalogAuctionList } from '@/admin/api/catalogAuction';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareTable } from '@/lib/CatalogsAuctions';
import FilterEntity from './catalogFilter';
import CatalogsAuctionsFilter from './catalogFilterEntity';
import { FilterCheckboxParamsInterface, FilterSearchParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { generateFilter } from '@/lib/utils/Utils';

const MODULE_NAME = 'AuctionHouseCatalogs';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class AuctionHouseCatalogsModule extends PageBaseModule {
    auctionHouseName = '';
    filter: any;

    constructor(module: AuctionHouseCatalogsModule) {
        super(module);

        const page = new PageEntity();
        page.setSortName('auctionHouseCatalogsSort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new FilterEntity();
        this.filter = getModule(CatalogsAuctionsFilter);
        this.filter.setFilterName('auctionHouseCatalogsFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_AUCTION_HOUSE_NAME(name: string) {
        this.auctionHouseName = name;
    }

    @Action({ rawError: true })
    async init(houseName: string) {
        this.context.commit('SET_AUCTION_HOUSE_NAME', houseName);
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        const result = await getCatalogAuctionList(
            this.pageSettings.pageCurrent,
            sort,
            generateFilter({ id: 'sellerAuctionHouseName', value: this.auctionHouseName }) + this.filter.filterSettings.filter,
            0,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.getProcessedTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    async getProcessedTable(table: TableApiInterface) {
        table.sortFields = table.sortFields.filter((item) => item !== 'sellerAuctionHouseName');

        return await prepareTable(table);
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_TABLE', {});
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateCheckboxFilter(params: FilterCheckboxParamsInterface): Promise<void> {
        await this.filter.updateCheckbox(params);
        this.filter.updateFilter();
    }
}

export default getModule(AuctionHouseCatalogsModule);
