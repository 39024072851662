export default {
    props: {
        data: {
            type: Object,
            default() {
                return {
                    key: 'time',
                    type: 'time-range',
                    title: 'Интервал смены',
                    placeholder: '',
                    value: '9:00',
                    hour: '17',
                    minute: '00',
                    action: 'user/auth/updateLogin',
                    errorHandler: 'user/auth/setValidateState',
                    required: true,
                    start: 0,
                    end: 24,
                    step: 5,
                    error: {
                        class: '',
                        message: '',
                    },
                    validation: {
                        empty: 'Поле не заполнено',
                        incorrect: '',
                    },
                };
            },
        },
        disabled: {
            type: Boolean,
        },
        allowedHours: {
            type: Function | Array,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        documentClick(e) {
            const el = this.$refs.timePickerDefault;
            const target = e.target;

            if (this.visible === true) {
                if (el && el !== target && !el.contains(target)) {
                    this.visible = false;
                    this.$emit('close');
                }
            }
        },
        allowedSteps(m) {
            return m % this.data.step === 0;
        },
        openRange() {
            if (this.data.disabled === true || this.disabled === true) {
                return;
            }

            this.visible = !this.visible;
        },
        selectHourValue(value) {
            value = parseInt(value) < 10 ? `0${value}` : value;
            const newValue = {
                type: 'hour',
                value: value,
            };

            this.$emit('click', newValue);
        },
        selectMinuteValue(value) {
            value = parseInt(value) < 10 ? `0${value}` : value;
            const newValue = {
                type: 'minute',
                value: value,
            };

            this.visible = false;
            this.$emit('click', newValue);
        },
    },
    created() {
        document.body.addEventListener('click', this.documentClick);
    },
    updated() {
        document.body.addEventListener('click', this.documentClick);
    },
    destroyed() {
        document.body.addEventListener('click', this.documentClick);
    },
};
