
import Layout from '@/admin/layouts/Default.vue';
import { Vue, Component } from 'vue-property-decorator';

import Profile from './profile/Main.vue';
import Catalogs from './_catalogs.vue';
import Catalog from '@/admin/views/auctions/card/Main.vue';
import Lots from './_lots.vue';
import Users from './_users.vue';
import Orders from './_orders.vue';
import Balance from './_balance.vue';
import ApprovedUsers from './_approvedUsers.vue';
import ApprovedUser from './approvedUser/Index.vue';

import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import AuctionHouseModule from '@/admin/store/sellers/auctionHouses';
import AuctionHouseEntity from '@/admin/store/sellers/auctionHouses/entity';
import AuctionHouseTransactionEntity from '@/admin/store/sellers/auctionHouses/transactionEntity';
import { API_URL } from '@/constants';

@Component({
    components: {
        Layout,
        Profile,
        Catalogs,
        Catalog,
        Lots,
        Users,
        Orders,
        Balance,
        ApprovedUsers,
        ApprovedUser,
        TitleReturn,
        TabsNav,
    },
})
export default class AuctionHouseMain extends Vue {
    returnTitleData = {
        icon: 'store',
        title: this.auctionHouseName,
    };

    get auctionHouseName() {
        return AuctionHouseEntity.auctionHouseName;
    }

    get tabsNav() {
        return AuctionHouseModule.allTabs;
    }

    get currentTab() {
        if (String(this.$route.name).indexOf('auction_house_approved_user') !== -1) {
            return 'auction_house_approved_users';
        }

        if (String(this.$route.name).indexOf('auction_house_catalog') !== -1) {
            return 'auction_house_catalogs';
        }

        if (this.$route.name != null) {
            return this.$route.name;
        }

        return '';
    }

    get publicLink() {
        if (this.$route.name === 'auction_house_add') {
            return '';
        }

        return `${API_URL}/auction-house/${this.$route.params.auctionHouseId}`;
    }

    returnBefore() {
        this.$router.push({ name: 'auction_houses' });
    }

    async created() {
        if (this.$route.name === 'auction_house_add') {
            this.returnTitleData = AuctionHouseEntity.titleReturnData.create;

            return;
        }

        await AuctionHouseEntity.initCardNameById(this.$route.params.auctionHouseId);
        AuctionHouseTransactionEntity.initSellerInfo({
            id: +this.$route.params.auctionHouseId,
            value: AuctionHouseEntity.titleReturnData.edit.title,
        });
        this.returnTitleData = AuctionHouseEntity.titleReturnData.edit;
    }

    beforeDestroy() {
        AuctionHouseEntity.resetAuctionHouseName();
    }
}
