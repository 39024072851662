
import { Vue, Component } from 'vue-property-decorator';
import ApprovedUserEntity from '@/admin/store/sellers/auctionHouses/approvedUsersEntity';
import { ApprovedUserModelInterface, ApprovedUserInfoInterface } from '@/interfaces/models/approvedUser.interface';

import InputBlock from '@/components/input/InputMain.vue';

import Inputmask from 'inputmask';
import { numberRegExp } from '@/constants';
import { formatNumberSpaced, formatCreatedAt, formatDateNowDays } from '@/lib/utils/Utils';

@Component({
    components: { InputBlock },
})
export default class AuctionHouseMemberStatisticsComponent extends Vue {
    get model(): ApprovedUserModelInterface {
        return ApprovedUserEntity.model;
    }

    get info(): ApprovedUserInfoInterface {
        return ApprovedUserEntity.info;
    }

    get createdAt(): string {
        if (!this.info.member.createdAt) {
            return '–';
        }

        return formatCreatedAt(this.info.member.createdAt);
    }

    get createdAtNow(): string {
        const result = formatDateNowDays(this.info.member.createdAt);

        if (!result) {
            return '';
        }

        return `(${result})`;
    }

    get approvalAt(): string {
        if (!this.info.approvalAt) {
            return '–';
        }

        return formatCreatedAt(this.info.approvalAt);
    }

    get approvalAtNow(): string {
        const result = formatDateNowDays(this.info.approvalAt);

        if (!result) {
            return '';
        }

        return `(${result})`;
    }

    get statusAuctionHouse(): string {
        if (this.info?.auctionHouseStatusName) {
            return this.info.auctionHouseStatus;
        }

        const status = this.info.auctionHouseStatus;

        if (status === 'registered') {
            return 'Зарегистрирован';
        }

        return status;
    }

    updateNewbieLimit(value: string): void {
        ApprovedUserEntity.updateNewbieLimit(formatNumberSpaced(value));
    }

    initMask(): void {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    mounted(): void {
        this.initMask();
    }
}
