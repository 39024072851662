import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '../../page';

import store from '@/admin/store';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { getAuctionHouseMembersList } from '@/admin/api/auctionHouse';
import PageEntity from '@/lib/layouts/page/pageEntity';

const MODULE_NAME = 'approvedUsers';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ApprovedUsersModule extends PageBaseModule {
    auctionHouseId: number | null = null;

    constructor(module: ApprovedUsersModule) {
        super(module);

        const page = new PageEntity();
        page.setSortName('auctionHouseApprovedUsersSort');
        page.setTitle('Одобренные пользователи');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;
    }

    @Mutation
    SET_AUCTION_HOUSE_ID(id: number | null) {
        this.auctionHouseId = id;
    }

    @Action({ rawError: true })
    init(auctionHouseId: number) {
        this.SET_AUCTION_HOUSE_ID(auctionHouseId);
        this.getTable();
    }

    @Action({ rawError: true })
    async getTable() {
        if (this.auctionHouseId == null) {
            return;
        }

        this.SET_IS_LOADING(true);

        const sort = await this.getSortForRequest();
        const result = await getAuctionHouseMembersList(
            this.pageSettings.pageCurrent,
            sort,
            `&filters[0][id]=auctionHouse&filters[0][value]=${this.auctionHouseId}`,
            +this.pageSettings.pageAmountItems,
        );

        this.SET_TABLE(await this.prepareTable(result));

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface): TableInterface {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => !title.visible)
            .map((title) => title.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'name') {
                    cell = `<a href="/sellers/auction_house/${this.auctionHouseId}/approved_users/${row['id']}" class="cp-table__item-link">
                                <span class="cp-table-column__accent">${cell}</span>
                            </a>`;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination,
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    reset() {
        this.RESET_TABLE();
        this.SET_AUCTION_HOUSE_ID(null);
    }
}
export default getModule(ApprovedUsersModule);
