import { TableApiInterface, TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { formatCreatedAt } from './utils/Utils';
import UserModule from '@/admin/store/user';

export const registerMap = new Map([
    [true, 'Отменить регистрацию'],
    [false, 'Зарегистрироваться'],
]);

export function prepareTable(table: TableApiInterface): TableInterface {
    const hiddenColumnsNames = ['sellerAuctionHouseId', 'statusName', 'youRegistered'];
    let hiddenColumns: string[] = [];
    const rows: any = [];

    table.titles = table.titles.map((title: TableTitle) => {
        if (!hiddenColumnsNames.includes(title.id)) {
            return title;
        }

        return {
            ...title,
            visible: false,
        };
    });
    table.titles.push({ id: '_actions', name: '', visible: true });

    hiddenColumns = table.titles.reduce((hiddenTitlesIds: string[], current: TableTitle) => {
        if (current.visible === false) {
            hiddenTitlesIds.push(current.id);
        }

        return hiddenTitlesIds;
    }, []);

    let index: string;
    let row: any;
    for ([index, row] of Object.entries(table.rows)) {
        const rowProcessed: any = {};
        for (const i of Object.keys(row)) {
            let cell: any = row[i];

            if (i === 'status') {
                cell = row['statusName'];
            }

            if (i === 'name') {
                cell = `<span class="cp-table-column__accent">${cell || row.id}</span>`;
            }

            if (i === 'photo') {
                cell = cell ? `<img src="${cell}" width="80" class="cp-input-radius-25" />` : '';
            }

            if (i === 'sellerAuctionHouseName') {
                const auctionHouseId = row['sellerAuctionHouseId'];
                cell = auctionHouseId
                    ? `<a href="${window.location.origin}/sellers/auction_house/${auctionHouseId}" target="_blank" class="cp-table__item-link">
                        <span class="cp-table-column__bold">${cell}</span>
                    </a>`
                    : cell;
            }

            if (i === 'dateTimeStartTrad') {
                cell = cell ? formatCreatedAt(cell) : '';
            }

            rowProcessed[i] = cell;
        }

        if ('youRegistered' in rowProcessed && UserModule.isOperator) {
            rowProcessed['_actions'] = [registerMap.get(row.youRegistered)];
        }

        rows[index] = rowProcessed;
    }

    return {
        titles: table.titles,
        rows: rows,
        sortFields: table.sortFields,
        pagination: table.pagination ? table.pagination : {},
        totalItems: table.totalItems,
        filters: table.filters,
        hiddenColumns: hiddenColumns,
    } as TableInterface;
}
