
import { Component, Vue } from 'vue-property-decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import auctionsEntityModule from '@/admin/store/auctions/catalogsAuctions/entity';
import AuctionsCommonEntityModule from '@/admin/store/auctions/catalogsAuctions/entityCommon';
import { API_URL } from '@/constants';

import Loading from '@/components/Loading.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import BtnSubmitFake from '@/components/buttons/BtnSubmitFake/BtnSubmitFake.vue';

import Profile from './_profile.vue';
import CommonRules from './_commonRules.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import responseHandler from '@/admin/store/modules/responseHandler';

import { getLotsList } from '@/admin/api/lots';

import UserModule from '@/admin/store/user';

@Component({
    components: {
        RemoveModal,
        TabsNav,
        TitleReturn,
        Loading,
        Profile,
        CommonRules,
        BtnDefault,
        BtnSubmit,
        BtnSubmitFake,
    },
})
export default class AuctionCommon extends Vue {
    titleReturnData = {
        icon: 'book',
        title: '',
    };

    showLink = true;
    showBtnLink = false;

    get isShowPublish(): boolean {
        return !this.isCatalogCreatePage && AuctionsCommonEntityModule.isAllowPublish;
    }

    get isShowUnpublish(): boolean {
        return !this.isCatalogCreatePage && AuctionsCommonEntityModule.isAllowUnpublish;
    }

    get isShowEdit(): boolean {
        return this.isCatalogCreatePage || AuctionsCommonEntityModule.isAllowEdit;
    }

    get isShowRemove(): boolean {
        return !this.isCatalogCreatePage && AuctionsCommonEntityModule.isAllowRemove;
    }

    get isShowClone(): boolean {
        return !this.isCatalogCreatePage && AuctionsCommonEntityModule.isAllowClone;
    }

    get isOperator(): boolean {
        return UserModule.isOperator;
    }

    get isCatalogCreatePage(): boolean {
        return this.$route.name === 'auction_create';
    }

    get tabsNav() {
        return auctionsEntityModule.tabsNav;
    }

    get currentTab(): string {
        return auctionsEntityModule.allTabs.common.id;
    }

    get isLoading(): boolean {
        return AuctionsCommonEntityModule.isLoading;
    }

    get titleLink(): string {
        if (this.$route.name === 'auction_create') {
            return '';
        }

        return `${API_URL}/auctions/${this.$route.params.catalogId}`;
    }

    returnBefore(): void {
        this.$router.push({ name: 'auctions' });
    }

    publish(): void {
        this.send(true);
    }

    unpublish(): void {
        AuctionsCommonEntityModule.unpublish(this.$route.params.catalogId);
    }

    async cloneCatalog(): Promise<void> {
        const newCatalogId = await AuctionsCommonEntityModule.sendCloneCatalog(this.$route.params.catalogId);

        if (!newCatalogId) {
            return;
        }

        this.$router.push({ name: 'auction', params: { catalogId: String(newCatalogId) } });
        responseHandler.showNotify({ message: 'Теперь вы находитесь в новом клонированном каталоге', type: 'ok' });
    }

    send(publish?: boolean): void {
        if (this.isCatalogCreatePage) {
            this.sendCreate();

            return;
        }

        this.sendEdit(publish);
    }

    sendEdit(publish?: boolean | Event): void {
        AuctionsCommonEntityModule.sendEdit(typeof publish === 'boolean' ? publish : undefined);
    }

    sendCreate(): void {
        AuctionsCommonEntityModule.sendAdd();
    }

    toLink() {
        if (!this.showLink) {
            responseHandler.showNotify({ message: 'Добавьте хотя-бы один лот', type: 'fail' });

            return;
        }

        window.open(this.titleLink, '_blank')
    }

    async getListLots() {
        const filter = `&filters[0][id]=auction&filters[0][value]=${this.$route.params.catalogId}`;

        const result = await getLotsList(1, '', filter, 0);
        const rows: any = result.rows;

        if (!rows.length) {
            this.showLink = false;
        }

        const lotsIsDraft = rows.filter((item: { status: string }) => {
            return item.status === 'draft';
        });

        if (rows.length === lotsIsDraft.length) {
            this.showLink = false;
        }
    }

    async mounted(): Promise<void> {
        auctionsEntityModule.initTabsItems();

        if (this.isCatalogCreatePage) {
            this.titleReturnData.title = AuctionsCommonEntityModule.titleReturnData.create.title;

            return;
        }
;
        this.titleReturnData.title = AuctionsCommonEntityModule.titleReturnData.edit.title;
        await this.getListLots();

        this.showBtnLink = true;
    }
}
