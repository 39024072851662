
import { Vue, Component, Watch } from 'vue-property-decorator';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TableBlock from '@/components/table/Table.vue';
import Loading from '@/components/Loading.vue';

import CatalogsModule from '@/admin/store/sellers/auctionHouses/catalogs';
import AuctionHouseEntity from '@/admin/store/sellers/auctionHouses/entity';

@Component({
    components: {
        AppHeaderMain,
        TableBlock,
        Loading,
    },
})
export default class AuctionHouseCatalogComponent extends Vue {
    get settings() {
        return CatalogsModule.pageSettings;
    }

    get filterSettings() {
        return CatalogsModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return CatalogsModule.isLoading;
    }

    get houseName() {
        return AuctionHouseEntity.auctionHouseName;
    }

    @Watch('houseName')
    onHouseNameChange() {
        CatalogsModule.init(this.houseName);
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            CatalogsModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        CatalogsModule.getList();
    }

    async resetFilters() {
        await CatalogsModule.clearSort();
        CatalogsModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'auction_create', params: { auctionHouseId: this.$route.params.auctionHouseId } });
    }

    created() {
        // TODO AB-77: init catalogs by auction house id
        if (!this.houseName) {
            return;
        }

        CatalogsModule.init(this.houseName);
    }

    beforeDestroy(): void {
        CatalogsModule.reset();
    }
}
