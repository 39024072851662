
import { Vue, Component } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import AuctionHouseOrders from '@/admin/store/sellers/auctionHouses/orders';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/TableLots.vue';

@Component({
    components: {
        FlexLayout,
        AppHeaderMain,
        Loading,
        TableBlock,
    },
})
export default class AuctionHouseOrdersComponent extends Vue {
    get isLoading() {
        return AuctionHouseOrders.isLoading;
    }

    get settings() {
        return AuctionHouseOrders.pageSettings;
    }

    get filterSettings() {
        return AuctionHouseOrders.filter.filterSettings;
    }

    get auctionHouseId() {
        return this.$route.params.auctionHouseId;
    }

    updateList() {
        AuctionHouseOrders.getTable(this.auctionHouseId);
    }

    async resetFilters() {
        await AuctionHouseOrders.clearSort();
        AuctionHouseOrders.getTable(this.auctionHouseId);
    }

    created() {
        AuctionHouseOrders.init(this.auctionHouseId);
    }

    beforeDestroy(): void {
        AuctionHouseOrders.reset();
    }
}
