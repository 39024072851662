
import { Vue, Component } from 'vue-property-decorator';
import { ApprovedUserModelInterface } from '@/interfaces/models/approvedUser.interface';
import ApprovedUserEntity from '@/admin/store/sellers/auctionHouses/approvedUsersEntity';
import BtnDefault from '@/components/BtnDefault.vue';
import { formatPhone } from '@/lib/utils/Utils';

@Component({
    components: {
        BtnDefault,
    },
})
export default class ApprovedUserInfoComponent extends Vue {
    auctionHouseName = '';

    get model(): ApprovedUserModelInterface {
        return ApprovedUserEntity.model;
    }

    get phone(): string {
        if (this.model.member.phone?.length) {
            return formatPhone(this.model.member.phone);
        }

        return '';
    }

    approve(): void {
        if (!+this.model.newbieLimit.value) {
            ApprovedUserEntity.promote();

            return;
        }

        ApprovedUserEntity.approve();
    }

    promote(): void {
        ApprovedUserEntity.promote();
    }

    block(): void {
        ApprovedUserEntity.block();
    }

    unblock(): void {
        ApprovedUserEntity.unblock();
    }

    cancel(): void {
        ApprovedUserEntity.cancel({
            name: 'auction_house_approved_users',
            params: { auctionHouseId: this.$route.params.auctionHouseId },
        });
    }
}
