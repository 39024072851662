
import { Component, Mixins } from 'vue-property-decorator';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import BtnDefault from '@/components/BtnDefault.vue';
import InputBlock from '@/components/form/InputMain.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import Loading from '@/components/Loading.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import TextareaCustom from '@/components/form/Textarea.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';
import InputLocalization from '@/components/input/InputLocalization.vue';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';

import EditorModalLocalization from '@/components/modules/_editorModalLocalization.vue';
import Editor from '@/components/modules/Editor.vue';
import AuctionHouseEntity from '@/admin/store/sellers/auctionHouses/entity';
import AuctionHouseMixin from './AuctionHouseMixin';
import UserModule from '@/admin/store/user';
import Requisites from './requisites.vue';

import Rules from './_rules.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { Localization, LocalizationParams } from '@/interfaces/localization';

import Inputmask from 'inputmask';
import { slugRegExp } from '@/constants';

@Component({
    components: {
        RemoveModal,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        BtnDefault,
        InputBlock,
        SelectCustom,
        EditorModalLocalization,
        Editor,
        BtnSubmit,
        ImageBlock,
        Loading,
        Rules,
        InputSuggestions,
        TextareaCustom,
        CheckboxCustom,
        Requisites,
        InputLocalization,
    },
})
export default class AuctionHousesCard extends Mixins(AuctionHouseMixin, LocalizationLayout) {
    get module(): typeof AuctionHouseEntity {
        return AuctionHouseEntity;
    }

    get modalParams() {
        return AuctionHouseEntity.modalParams;
    }

    get auctionHouseName() {
        return AuctionHouseEntity.auctionHouseName;
    }

    get isLoading() {
        return AuctionHouseEntity.isLoading;
    }

    get isRoleSuperadmin(): boolean {
        return UserModule.isSuperAdmin;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    updateName(params: LocalizationParams): void {
        AuctionHouseEntity.updateLocalizationField({ ...params, field: 'name' });
    }

    updateSelectedCountry(selected: { id: number; value: string }) {
        AuctionHouseEntity.updateSelectedCountry(selected);
    }

    updateSearchCountry(search: string) {
        AuctionHouseEntity.searchCountry(search);
    }

    updateSelectedCity(selected: InputSuggestionItem) {
        AuctionHouseEntity.updateSuggestionsSelectedByType({ selected, type: 'city' });
    }

    updateSearchCity(search: string) {
        AuctionHouseEntity.searchCity(search);
    }

    updateAddress(params: LocalizationParams): void {
        AuctionHouseEntity.updateLocalizationField({ ...params, field: 'address' });
    }

    updateSite(value: string) {
        AuctionHouseEntity.updateSite(value);
    }

    updatePhone(value: string) {
        AuctionHouseEntity.updatePhone(value);
    }

    updateEmail(value: string) {
        AuctionHouseEntity.updateEmail(value);
    }

    updateSort(value: string) {
        AuctionHouseEntity.updateSortOrder(formatRemoveSpaces(value));
    }

    updateSlug(value: string): void {
        AuctionHouseEntity.updateSlug(value);
    }

    updateTermsValue(params: Localization): void {
        AuctionHouseEntity.SET_LOCALIZATION_BY_FIELD({ data: params, fieldName: 'terms' });
    }

    updateRegulationsValue(params: Localization): void {
        AuctionHouseEntity.SET_LOCALIZATION_BY_FIELD({ data: params, fieldName: 'regulations' });
    }

    handleImage(file: string) {
        AuctionHouseEntity.updatePhotoFile(file);
    }

    removePreview() {
        AuctionHouseEntity.removeImagePreview();
    }

    updateTopSeller(params: { name: string; id: number; bool: boolean }) {
        AuctionHouseEntity.updateTopSeller(params.bool);
    }

    updateNewbieAutoApprove(params: { name: string; id: number; bool: boolean }) {
        AuctionHouseEntity.updateNewbieAutoApprove(params.bool);
    }

    closeModal() {
        AuctionHouseEntity.toggleModal({ key: 'terms', bool: false });
        AuctionHouseEntity.toggleModal({ key: 'regulations', bool: false });
    }

    openModal(key: string) {
        AuctionHouseEntity.toggleModal({ key, bool: true });
    }

    returnBefore() {
        this.$router.push({ name: 'auction_houses' });
    }

    async send() {
        if (this.$route.params.auctionHouseId) {
            this.sendEdit();

            return;
        }

        this.sendAdd();
    }

    sendEdit() {
        AuctionHouseEntity.sendEdit();
    }

    sendAdd() {
        AuctionHouseEntity.sendAdd({ name: 'auction_houses' });
    }

    initMask(): void {
        const settingsMaskSlug = new Inputmask({ regex: slugRegExp, showMaskOnHover: false });
        const inputsSlug = document.getElementsByClassName('j_slug_mask_wrapper');

        for (const item of Object.values(inputsSlug)) {
            settingsMaskSlug.mask(item.getElementsByTagName('input')[0]);
        }
    }
}
