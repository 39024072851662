
import { Component } from 'vue-property-decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import auctionsCommonEntityModule from '@/admin/store/auctions/catalogsAuctions/entityCommon';

import InputBlock from '@/components/form/InputMain.vue';
import InputLocalization from '@/components/input/InputLocalization.vue';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import CatalogImages from '@/components/templates/CatalogImageInputs.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import TimePicker from '@/components/form/TimePickerDefault.vue';
import TextareaCustom from '@/components/form/Textarea.vue';
import CheckboxCustom from '@/components/form/Checkbox.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';

import moment from 'moment';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { LocalizationParams } from '@/interfaces/localization';
import Inputmask from 'inputmask';
import { slugRegExp } from '@/constants';

@Component({
    components: {
        TabsNav,
        TitleReturn,
        InputBlock,
        InputLocalization,
        SelectCustom,
        CatalogImages,
        DatePicker,
        TimePicker,
        TextareaCustom,
        CheckboxCustom,
        InputSuggestions,
    },
})
export default class AuctionProfileComponent extends LocalizationLayout {
    get model() {
        return auctionsCommonEntityModule.model;
    }

    get returnTitleData() {
        return this.$route.params.catalogId
            ? auctionsCommonEntityModule.titleReturnData.edit
            : auctionsCommonEntityModule.titleReturnData.create;
    }

    updateName(params: LocalizationParams): void {
        auctionsCommonEntityModule.updateLocalizationField({ ...params, field: 'name' });
    }

    updateSearchCountry(search: string) {
        auctionsCommonEntityModule.searchCountry(search);
    }

    updateCountry(country: InputSuggestionItem) {
        auctionsCommonEntityModule.updateSelectedCountry(country);
    }

    updateSearchCity(search: string) {
        auctionsCommonEntityModule.searchCity(search);
    }

    updateSelectedCity(city: InputSuggestionItem) {
        auctionsCommonEntityModule.updateSuggestionsSelectedByType({ selected: city, type: 'city' });
    }

    updateAddress(params: LocalizationParams): void {
        auctionsCommonEntityModule.updateLocalizationField({ ...params, field: 'address' });
    }

    updateSearchAuctionHouse(search: string) {
        auctionsCommonEntityModule.searchAuctionHouse(search);
    }

    updateSelectedAuctionHouse(selected: InputSuggestionItem) {
        auctionsCommonEntityModule.updateSuggestionsSelectedByType({ type: 'sellerAuctionHouse', selected });

        if (this.$route.name === 'auction_create' && typeof selected.id === 'number') {
            auctionsCommonEntityModule.updateAuctionDataByAuctionHouse(selected.id);
        }
    }

    updateDescription(params: LocalizationParams): void {
        auctionsCommonEntityModule.updateLocalizationField({ ...params, field: 'description' });
    }

    updateSlug(value: string): void {
        auctionsCommonEntityModule.updateModelFieldValue({ field: 'slug', value });
    }

    handleDate(params: any) {
        const dateFormatted = moment(`${params.day}/${params.month}/${params.year}`, 'DD/MM/YYYY').format('DD/MM/YY');
        auctionsCommonEntityModule.updateDate(dateFormatted);
    }

    updateTime(params: { type: string; value: number | string }) {
        auctionsCommonEntityModule.updateTime(params);
    }

    updateStreamUrl(url: string) {
        auctionsCommonEntityModule.updateStreamUrl(url);
    }

    initMask() {
        const settingsMaskSlug = new Inputmask({ regex: slugRegExp, showMaskOnHover: false });
        const inputsSlug = document.getElementsByClassName('j_slug_mask_wrapper');

        for (const item of Object.values(inputsSlug)) {
            settingsMaskSlug.mask(item.getElementsByTagName('input')[0]);
        }
    }

    mounted() {
        this.initMask();
    }
}
