import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '@/admin/store';
import BaseEntityModule from '@/admin/store/entity';
import {
    getAuctionHouseMember,
    editAuctionHouseMember,
    cancelAuctionHouseMember,
    approveAuctionHouseMember,
} from '@/admin/api/auctionHouse';
import {
    ApprovedUserInterface,
    ApprovedUserModelInterface,
    ApprovedUserMemberInterface,
    ApprovedUserInfoInterface,
} from '@/interfaces/models/approvedUser.interface';
import tabs from './approvedUsersTabs';
import InputEntity from '@/lib/formFactory/inputEntity';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { formatRemoveSpaces, getApiErrorMessage } from '@/lib/utils/Utils';
import { tabsNavInterface } from '@/interfaces/tabs';
import redirectHandlerModule from '@/admin/store/modules/redirectHandler';

const MODULE_NAME = 'approvedUser';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ApprovedUser extends BaseEntityModule {
    model: ApprovedUserModelInterface;
    info: ApprovedUserInfoInterface;

    tabsNav: tabsNavInterface = {};

    constructor(module: ApprovedUser) {
        super(module);

        this.tabsNav = tabs;

        const newbieLimitEntity = new InputEntity('newbieLimit');
        newbieLimitEntity.setTitle('Лимит');

        this.info = {
            id: 0,
            member: {
                id: 0,
                email: '',
                phone: '',
                fullName: '',
                numberOfDaysSinceRegistration: 0,
                createdAt: '',
            },
            status: '',
            statusName: '',
            prevStatus: null,
            newbieLimit: 0,
            totalPurchasesAmount: 0,
            approvalAt: '',
            auctionHouseName: '',
            auctionHouseStatus: '',
            collectionnerRequired: false,
            createdAt: '',
            numberOfAuctionsWithBids: 0,
            numberOfBuysInAuctionHouse: 0,
            numberOfDaysSinceApproved: 0,
            reason: null,
        };

        this.model = {
            id: 0,
            member: {
                id: 0,
                email: '',
                phone: null,
                fullName: '',
            },
            status: '',
            statusName: '',
            prevStatus: null,
            newbieLimit: newbieLimitEntity.entity[newbieLimitEntity.entityName],
            totalPurchasesAmount: 0,
        };
    }

    @Mutation
    SET_MEMBER(member: ApprovedUserMemberInterface) {
        this.model.member = member;
    }

    @Mutation
    SET_INFO(member: ApprovedUserInfoInterface) {
        this.info = member;
    }

    @Mutation
    SET_NEWBIE_LIMIT(newbieLimit: string | number) {
        this.model.newbieLimit.value = typeof newbieLimit === 'number' ? String(newbieLimit) : newbieLimit;
    }

    @Mutation
    SET_STATUS(status: string) {
        this.model.status = status;
    }

    @Mutation
    SET_STATUS_NAME(statusName: string) {
        this.model.statusName = statusName;
    }

    @Mutation
    SET_PREV_STATUS(status: string) {
        this.model.prevStatus = status;
    }

    @Mutation
    SET_TOTAL_PURCHASES_AMOUNT(totalPurchasesAmount: number) {
        this.model.totalPurchasesAmount = totalPurchasesAmount;
    }

    @Action({ rawError: true })
    async initById(id: number) {
        this.SET_IS_LOADING(true);

        const result = await getAuctionHouseMember(id);
        await this.setInfo(result);

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    setInfo(data: ApprovedUserInterface) {
        this.SET_ID(String(data.id));
        this.SET_MEMBER(data.member);
        this.SET_NEWBIE_LIMIT(data.newbieLimit);
        this.SET_STATUS(data.status);
        this.SET_STATUS_NAME(data.statusName);
        this.SET_PREV_STATUS(data.prevStatus || '');
        this.SET_TOTAL_PURCHASES_AMOUNT(data.totalPurchasesAmount);
        this.SET_INFO(data as ApprovedUserInfoInterface);
    }

    @Action({ rawError: true })
    updateNewbieLimit(value: string) {
        this.SET_NEWBIE_LIMIT(value);
    }

    @Action({ rawError: true })
    async approve() {
        try {
            const result = await approveAuctionHouseMember(+this.id);

            if (result === 'ok') {
                this.initById(+this.id);
                ResponseHandlerModule.showNotify({ message: 'Регистрация пользователя подтверждена', type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async promote() {
        try {
            await approveAuctionHouseMember(+this.id);

            const result = await editAuctionHouseMember(+this.id, { status: 'collector' });

            if (result.id) {
                this.initById(+this.id);
                ResponseHandlerModule.showNotify({ message: 'Пользователь повышен до коллекционера', type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async block() {
        try {
            const result = await editAuctionHouseMember(+this.id, { status: 'blocked' });

            if (result.id) {
                this.initById(+this.id);
                ResponseHandlerModule.showNotify({ message: 'Пользователь заблокирован', type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async unblock() {
        if (typeof this.model.prevStatus !== 'string') {
            return;
        }

        try {
            const result = await editAuctionHouseMember(+this.id, { status: this.model.prevStatus });

            if (result.id) {
                this.initById(+this.id);
                ResponseHandlerModule.showNotify({ message: 'Пользователь разблокирован', type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async cancel(redirect: any = null) {
        try {
            const result = await cancelAuctionHouseMember(+this.id);

            if (result === 'ok') {
                redirectHandlerModule.changeUrl(redirect);
                ResponseHandlerModule.showNotify({ message: 'Заявка отменена', type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async send() {
        try {
            const result = await editAuctionHouseMember(+this.id, {
                newbieLimit: +formatRemoveSpaces(this.model.newbieLimit.value),
            });

            if (result.id) {
                this.initById(+this.id);
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    reset() {
        this.SET_ID('');
        this.SET_MEMBER({ id: 0, email: '', phone: null, fullName: '' });
        this.SET_NEWBIE_LIMIT('');
        this.SET_STATUS('');
        this.SET_STATUS_NAME('');
        this.SET_PREV_STATUS('');
        this.SET_TOTAL_PURCHASES_AMOUNT(0);
    }
}
export default getModule(ApprovedUser);
